import axios from 'axios'
const FCM_ENDPOINT = 'https://fcm.googleapis.com/fcm/send'
const FCM_SENDKEY = process.env.VUE_APP_FCM_API_KEY

export function sendMessage(title, body, badge = 0, topic = 'ems') {
  // 送信するメッセージを作成する
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `key=${FCM_SENDKEY}`,
    },
  }

  const payload = {
    notification: {
      title: title,
      body: body,
      badge: badge,
    },
    to: '/topics/' + topic,
  }
  axios
    .post(FCM_ENDPOINT, payload, options)
    .then((response) => {
      console.log('Successfully sent message:', response.data) // eslint-disable-line no-console
    })
    .catch((error) => {
      console.error('Error sending message:', error) // eslint-disable-line no-console
    })
}

export function sendMessageForWeb(title, body, users) {
  // 送信するメッセージを作成する
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `key=${FCM_SENDKEY}`,
    },
  }
  Object.keys(users).forEach((id) => {
    let account = users[id]
    let webFcmToken = account.webFcmToken
    if (webFcmToken && webFcmToken.length > 0) {
      webFcmToken.forEach((token) => {
        const payload = {
          notification: {
            title: title,
            body: body,
          },
          to: token,
        }
        axios
          .post(FCM_ENDPOINT, payload, options)
          .then((response) => {
            console.log('Successfully sent message:', response.data) // eslint-disable-line no-console
          })
          .catch((error) => {
            console.error('Error sending message:', error) // eslint-disable-line no-console
          })
      })
    }
  })
}

<template lang="pug">
  transition(name="modal" appear)
    .modal.modal-overlay(@click.self="$emit('close')")
      .modal-window
        .modal-content
          p.ttl {{ title }}
          slot
        footer.modal-footer
          slot(name="footer")
            CancelBtn.cancel(@event="$emit('close')" :btnLabel="cancel")
            ActiveBtn.submit(@event="$emit('submit')" :btnLabel="submit" :isDisabled="isDisabled")
</template>
<script>
import ActiveBtn from '@/components/atoms/ActiveBtn.vue'
import CancelBtn from '@/components/atoms/CancelBtn.vue'
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '内容確認',
    },
    cancel: {
      type: String,
      required: false,
      default: 'キャンセル',
    },
    submit: {
      type: String,
      required: false,
      default: '登録',
    },
    isDisabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    ActiveBtn,
    CancelBtn,
  },
}
</script>
<style lang="scss" scoped>
.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .modal-window {
    background: #fff;
    border-radius: 5px;
    width: calc(100% - 30px);
    max-width: 600px;
    max-height: calc(100% - 40px);
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    position: relative;
    .modal-content {
      padding: 10px 15px 15px;
      .ttl {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding: 5px;
      }
    }
    .modal-footer {
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: rgb(187, 187, 187);
      text-align: right;
      box-shadow: 0 -2px 10px #f1f1f1;
      transition: transform 0.4s ease;
      button {
        width: 50%;
        padding: 12px 10px;
        margin: 0;
        &.cancel {
          border-right: 1px solid #d1d1d1;
        }
        &.submit {
          font-weight: bold;
        }
      }
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.4s;
}
.modal-enter-active .modal-window,
.modal-leave-active .modal-window {
  transition: opacity 0.4s, transform 0.4s;
}
.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.modal-enter .modal-window,
.modal-leave-to .modal-window {
  opacity: 0;
  transform: translateY(-20px);
}
</style>

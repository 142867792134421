<template lang="pug">
  button(@click="$emit('event')" :disabled="isDisabled")
    slot(name="btnLabel")
    | {{ btnLabel }}
</template>
<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    btnLabel: {
      type: String,
      required: true,
      default: '送信',
    },
  },
}
</script>
<style lang="scss" scoped>
button {
  display: block;
  background-color: $ems-base-color;
  background-image: $ems-base-gradation;
  color: #fff;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(64, 64, 64, 0.34);
  &:disabled {
    background-color: $ems-disabled-color;
    background-image: $ems-disabled-gradation;
    cursor: inherit;
  }
}
</style>

<template lang="pug">
  button(@click="$emit('event')")
    slot(name="btnLabel")
    | {{ btnLabel }}
</template>
<script>
export default {
  props: {
    btnLabel: {
      type: String,
      required: true,
      default: '送信',
    },
  },
}
</script>
<style lang="scss" scoped>
button {
  display: block;
  background-color: $ems-disabled-color;
  background-image: $ems-disabled-gradation;
  color: #fff;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(64, 64, 64, 0.34);
}
</style>

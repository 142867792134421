<template lang="pug">
  .register
    LoadingItem(v-if="loading")
    MainHeadItem(iconName="register" iconW="30" iconH="20" title="社員登録")
      IconRegister
    AccountForm(@submit="onRegister($event)")
</template>

<script>
import { postRegisterMessage } from '@/apis/slack'
import { sendMessage, sendMessageForWeb } from '@/apis/firebaseMessaging'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { mapActions, mapState } from 'vuex'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconRegister from '@/components/icons/IconRegister.vue'
import LoadingItem from '@/components/LoadingItem'
import AccountForm from '@/components/admin/AccountForm.vue'
dayjs.locale('ja')
export default {
  name: 'AdminRegisterView',
  metaInfo: {
    title: '【管理】社員登録画面',
  },
  components: {
    MainHeadItem,
    IconRegister,
    LoadingItem,
    AccountForm,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('masterModule', ['master']),
    ...mapState('usersModule', ['users']),
  },
  methods: {
    ...mapActions('usersModule', ['addUser']),
    async onRegister(event) {
      this.loading = true
      const formData = event.formData
      await this.addUser(formData)
      const hireDate = dayjs(new Date(formData.hireDate))
      // 入社日が未来の場合
      if (
        hireDate.isSame(dayjs(new Date())) ||
        hireDate.isAfter(dayjs(new Date()))
      ) {
        // Slack通知メッセージ
        const registerMsg =
          '> [' +
          hireDate.format('YYYY/M/D') +
          ']に\n> *' +
          this.master.departments[formData.departmentId].name +
          '* の *' +
          formData.name +
          '* さんが入社します:congratulations:\n> みなさんよろしくお願いします:tada::tada: \n\n' +
          window.location.protocol +
          '//' +
          window.location.host +
          '/members/' +
          formData.id
        postRegisterMessage(registerMsg)

        // アプリ用プッシュ通知
        sendMessage(
          '入社情報のお知らせ',
          hireDate.format('YYYY/M/D') +
            'に' +
            this.master.departments[formData.departmentId].name +
            'の' +
            formData.name +
            'さんが入社します',
          1,
          'ems',
        )
        // Web用Push通知
        sendMessageForWeb(
          '入社情報のお知らせ',
          hireDate.format('YYYY/M/D') +
            'に' +
            this.master.departments[formData.departmentId].name +
            'の' +
            formData.name +
            'さんが入社します',
          this.users,
        )
      }

      this.$router.push({
        name: 'admin_list',
        hash: '#id_' + formData.id,
      })
    },
  },
}
</script>
